import axios from "axios";
import {setError} from "./errorActions";
import store from "../store/store";


export const FETCH_ENUMERATION_REQUEST = 'FETCH_ENUMERATION_REQUEST';
export const FETCH_ENUMERATION_SUCCESS = 'FETCH_ENUMERATION_SUCCESS';
export const FETCH_ENUMERATION_FAILURE = 'FETCH_ENUMERATION_FAILURE';

export const CREATE_ENUMERATION_SUCCESS = 'CREATE_ENUMERATION_SUCCESS';
export const CREATE_ENUMERATION_FAILURE = 'CREATE_ENUMERATION_FAILURE';

export const UPDATE_ENUMERATION_SUCCESS = 'UPDATE_ENUMERATION_SUCCESS';
export const UPDATE_ENUMERATION_FAILURE = 'UPDATE_ENUMERATION_FAILURE';


export const DELETE_ENUMERATION_SUCCESS = 'DELETE_ENUMERATION_SUCCESS';
export const DELETE_ENUMERATION_FAILURE = 'DELETE_ENUMERATION_FAILURE';


export const fetchEnumerationRequest = () => {
    return {
        type: FETCH_ENUMERATION_REQUEST
    }
};

export const fetchEnumerationSuccess = Enumeration => {
    return {
        type: FETCH_ENUMERATION_SUCCESS, payload: Enumeration
    }
};
export const fetchEnumerationFailure = error => {
    return {
        type: FETCH_ENUMERATION_FAILURE, payload: error
    }
};


export const getEnumerations = (doctorId) => {
    return async dispatch => {

        return await axios.get(`api/enumeration/listByDoctorId/${doctorId}`,)
            .then(response => {
                return dispatch({
                    type: FETCH_ENUMERATION_SUCCESS, payload: response.data
                })
            }).catch(error => {
                    dispatch(setError(error))
            });


    }
};


export const createEnumeration = (enumeration) => {
    return (dispatch) => {
        return axios
            .post(`/api/enumeration/create/doctor/${store.getState().doctorReducer.doctor.id}`, enumeration)
            .then(res => dispatch({
                type: CREATE_ENUMERATION_SUCCESS, payload: res.data
            }))
            .catch(error => {
                if (error.response) {
                    dispatch(setError(error))
                }
                return dispatch({
                    type: CREATE_ENUMERATION_FAILURE, payload: error.response.data
                })
            });
    };
}


export const updateEnumeration = (enumeration) => {
    return (dispatch) => {
        return axios
            .put(`/api/enumeration/update`, enumeration)
            .then(res => dispatch({
                type: UPDATE_ENUMERATION_SUCCESS, payload: enumeration
            }))
            .catch(error => {
                if (error.response) {
                    dispatch(setError(error))
                }
                return dispatch({
                    type: UPDATE_ENUMERATION_FAILURE, payload: error.response.data
                })
            });
    };
}


export const deleteEnumeration = (enumeration) => {
    return (dispatch) => {
        return axios
            .delete(`/api/enumeration/delete/${enumeration}`)
            .then(res => dispatch({
                type: DELETE_ENUMERATION_SUCCESS, payload: enumeration
            }))
            .catch(error => {
                if (error.response) {

                    dispatch(setError(error))
                }
                return dispatch({
                    type: DELETE_ENUMERATION_FAILURE, payload: error.response.data
                })
            });
    };
}




