import {createStore, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';

import {composeWithDevTools} from "redux-devtools-extension/logOnlyInProduction";
import {combineReducers} from 'redux';
import logger from 'redux-logger';
import {errorReducer} from "../action/errorActions";
import {infoReducer} from "../action/infoActions";
import {warningReducer} from "../action/warningActions";
import doctorReducer from "../reducers/doctorReducer";
import enumerationReducer from "../reducers/enumerationReducer";
import journalReducer from "../reducers/journalReducer";
import openingHoursReducer from "../reducers/openingHoursReducer";

const middlewares = [thunk, logger ];



const store = createStore(combineReducers({
    doctorReducer, errorReducer, infoReducer, warningReducer,enumerationReducer, journalReducer, openingHoursReducer

}), composeWithDevTools(applyMiddleware(...middlewares)));
/*const socket = setupSocket(store.dispatch, store);
sagaMiddleware.run(handleNewMessage, {socket})
*/export default store;
