import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {HIDE_INFO} from "../action/infoActions";
import {Alert, AlertTitle, Snackbar} from "@mui/material";

const InfoSnack = (props) => {
    const isOpen = useSelector(state => state.infoReducer.isOpen);
    const info = useSelector(state => state.infoReducer.info);

    const dispatch = useDispatch();

    function handleClose(){
        dispatch({ type: HIDE_INFO });
    }

    return (
        <>
            {isOpen && info && (
                <Snackbar open={isOpen} autoHideDuration={6000} onClose={handleClose}
                          anchorOrigin={{
                              vertical: 'top',
                              horizontal: 'center'
                          }}
                >
                    <Alert onClose={handleClose} severity="success">
                        <AlertTitle>{info.status}</AlertTitle>
                        <strong>{info.statusText}</strong>
                    </Alert>
                </Snackbar>
            )}
        </>
    )
}
export default InfoSnack