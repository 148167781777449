import axios from "axios";
import {setError} from "./errorActions";
import store from "../store/store";
import {setInfo} from "./infoActions";


export const FETCH_JOURNAL_REQUEST = 'FETCH_JOURNAL_REQUEST';
export const FETCH_JOURNAL_SUCCESS = 'FETCH_JOURNAL_SUCCESS';
export const FETCH_JOURNAL_FAILURE = 'FETCH_JOURNAL_FAILURE';

export const CREATE_JOURNAL_SUCCESS = 'CREATE_JOURNAL_SUCCESS';
export const CREATE_JOURNAL_FAILURE = 'CREATE_JOURNAL_FAILURE';

export const DELETE_ALL_JOURNAL = 'DELETE_ALL_JOURNAL';


export const SET_JOURNAL_SERVED = 'SET_JOURNAL_SERVED';
export const SET_JOURNAL_SERVED_FAILD = 'SET_JOURNAL_SERVED_FAILD'
export const SET_JOURNAL_ABSENT = 'SET_JOURNAL_ABSENT';
export const SET_JOURNAL_ABSENT_FAILD = 'SET_JOURNAL_ABSENT_FAILD';

export const DELETE_JOURNAL_SUCCESS = 'DELETE_JOURNAL_SUCCESS';
export const DELETE_JOURNAL_FAILURE = 'DELETE_JOURNAL_FAILURE';


export const fetchJournalRequest = () => {
    return {
        type: FETCH_JOURNAL_REQUEST
    }
};

export const fetchJournalSuccess = Journal => {
    return {
        type: FETCH_JOURNAL_SUCCESS, payload: Journal
    }
};
export const fetchJournalFailure = error => {
    return {
        type: FETCH_JOURNAL_FAILURE, payload: error
    }
};


export const getJournals = () => {
    return async dispatch => {

        return await axios.get(`api/journal/list/${store.getState().doctorReducer.doctor.id}`,)
            .then(response => {
                return dispatch({
                    type: FETCH_JOURNAL_SUCCESS, payload: response.data
                })
                dispatch(setInfo("Success"))
            }).catch(error => {
                    dispatch(setError(error))
                }
                // dispatch({ type: CREATE_Journal_RECORD_FAILURE,
                //     payload: err.data})
            );


    }
};


export const setServed = (journal) => {
    return (dispatch) => {
        console.log(journal)
        return axios.put( `api/journal/served`, journal)
            .then((response) => {

                dispatch({type: SET_JOURNAL_SERVED, payload: journal})
                dispatch(setInfo("Success"))
            }).catch(error => {
                dispatch(setError(error))
                return dispatch({
                    type: SET_JOURNAL_SERVED_FAILD, payload: error.response.data
                })
            });
    }
}


export const setAbsent = (journal) => {
    return (dispatch) => {
        return axios.put(`api/journal/absent`,journal)
            .then((response) => {
                dispatch({type: SET_JOURNAL_ABSENT, payload: journal})
                dispatch(setInfo("Success"))
            }).catch(error => {
                dispatch(setError(error))
                return dispatch({
                    type: SET_JOURNAL_ABSENT_FAILD, payload: error.response.data
                })
            });

    }
}


export const deleteAllJournals = () => {
    return async (dispatch, getState) => {

        return await axios.put( `api/journal/deletebydoctor`, store.getState().doctorReducer.doctor
        )
            .then((response) => {

                dispatch({type: DELETE_ALL_JOURNAL, payload: []})
                dispatch(setInfo("Success"))
            }).catch(error => {

                dispatch(setError(error))
                return dispatch({
                    type: SET_JOURNAL_ABSENT_FAILD, payload: error.response.data
                })
            });

    }
}


