import {useDispatch, useSelector} from "react-redux";
import {Box, Button, ButtonGroup, Container, Grid, Paper, Stack, TextField, Typography} from "@mui/material";
import {Controller, useForm} from "react-hook-form";
import {updateDoctorProfile} from "../action/doctorAction";
import {useTheme} from "@mui/material/styles";

const DoctorDetail = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const doctorReducer = useSelector(state => state.doctorReducer);

    const {handleSubmit, control} = useForm({
        defaultValues: doctorReducer.doctor
    });
    const onSubmit = data => {
        dispatch(updateDoctorProfile(data))
    }
    return <>
        <Box  sx={{
            marginTop: 8,
            padding:3,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',



        }}>
            <Typography>{doctorReducer.doctor?.id}</Typography>

            <form onSubmit={handleSubmit(onSubmit)}
            >


                        <Paper sx={{
                            border: 8,
                            borderColor:theme.palette.primary
                        }}>
                            <Box p={3}>
                                <Stack direction="row" spacing={3}>



                                        <Stack spacing={3}>
                                        <Controller
                                            name="fullName"
                                            control={control}
                                            render={({field}) => (<TextField
                                                fullWidth
                                                label="Meno Priezvisko" {...field} />)}
                                        />

                                        <Controller
                                            name="loginName"
                                            control={control}
                                            render={({field}) => (<TextField
                                                fullWidth
                                                label="Prihlasovacie meno" {...field} />)}
                                        />

                                        <Controller
                                            name="address"
                                            control={control}
                                            render={({field}) => (<TextField
                                                fullWidth
                                                label="Adresa" {...field} />)}
                                        />
                                        <Controller
                                            name="type"
                                            control={control}
                                            render={({field}) => (<TextField label="Typ" {...field} />)}
                                        />


                                    </Stack>



                                        <Stack spacing={3}>
                                            <Controller
                                                name="companyName"
                                                control={control}
                                                render={({field}) => (
                                                    <TextField label="Nazov spolocnsti" {...field} />)}
                                            />
                                            <Controller
                                                name="website"
                                                control={control}
                                                render={({field}) => (<TextField label="Web stranka" {...field} />)}
                                            />

                                            <Controller
                                                name="mobile"
                                                control={control}
                                                render={({field}) => (<TextField label="Mobil" {...field} />)}
                                            />
                                            <Controller
                                                name="email"
                                                control={control}
                                                render={({field}) => (<TextField label="Email" {...field} />)}
                                            />

                                            <Controller
                                                name="comments"
                                                control={control}
                                                render={({field}) => (<TextField label="Komentar" {...field} />)}
                                            />

                                        </Stack>
                                    </Stack>




                            </Box>
                            <Box m={3}><ButtonGroup>
                                <Button type="submit" color="primary" variant="contained">Uložiť</Button>
                            </ButtonGroup>
                            </Box>
                        </Paper>








            </form>
        </Box>


    </>

}

export default DoctorDetail;