import React, { useCallback, useMemo, useState } from 'react';
import MaterialReactTable from 'material-react-table';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Stack,
    TextField,
    Tooltip, Typography,
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import {useDispatch, useSelector} from "react-redux";
import {CirclePicker} from "react-color";
import PaletteIcon from '@mui/icons-material/Palette';
import {createEnumeration, deleteEnumeration, updateEnumeration} from "../action/enumerationAction";
import {useTheme} from "@mui/material/styles";



const EnumerationList = () => {
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [color, setColor] = useState("");
    const dispatch = useDispatch();
    const handleCreateNewRow = (values) => {
        dispatch(createEnumeration(values))
    };

    const theme = useTheme();

    const handleSaveRowEdits = async ({exitEditingMode, row, values}) => {


        if(color !== ""){
            values.enumColor=color.trim()
        }

        dispatch(updateEnumeration(values)).then(  ()=>  exitEditingMode());

    };

    const handleDeleteRow = useCallback(
        (row) => {


            dispatch(deleteEnumeration(row.original.id));
        },
    );

    const enumerationReducer = useSelector(state => state.enumerationReducer);


    const columns = useMemo(
        () => [
            {
                accessorKey: 'id', //access nested data with dot notation
                header: 'id',
                enableEditing: false,

            },
            {
                accessorKey: 'enumName', //access nested data with dot notation
                header: 'Názov',
            },
            {
                accessorKey: 'maxCountPerDay', //access nested data with dot notation
                header: 'Denný limit',
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    type: 'number',
                }),

            },

            {
                accessorKey: 'enumColor',
                header: 'Farba',
                Cell: ({ cell }) => <PaletteIcon  style={{ color: cell.getValue() }}/>,
                Edit: ({ cell, column, table }) => <CirclePicker
                    color={cell.getValue() }
                    onChange={(color, event) => setColor(color.hex)}
                />,


            }

        ],
        [],
    );


    console.log(theme.palette.primary);
    return <Box         sx={{
        marginTop: 8,
        padding:3,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',

    }}>

        <Typography component="h1" variant="h5">Zoznam kategórii</Typography>

        <Box p={2} sx={{border: 8, borderColor:theme.palette.primary}} mt={2}>
            <MaterialReactTable

                columns={columns}
                enableEditing={true}
                enableTopToolbar={true} //hide top toolbar
                enableColumnActions={false}
                enableDensityToggle={false}
                enableHiding={false}
                enableFilters={false}
                enableGlobalFilter={false}
                enableFullScreenToggle={false}
                enableBottomToolbar={false}
                initialState={{columnVisibility:{id:false}}}
                editingMode="modal" //default
                localization={{  actions: 'Akcie',
                    and: 'a',
                    cancel: 'Zrušiť',
                    changeFilterMode: 'Změnit režim filtru',
                    changeSearchMode: 'Změnit režim hledání',
                    clearFilter: 'Vyčistit filtr',
                    clearSearch: 'Vyčistit hledání',
                    clearSort: 'vyčistit řazení',
                    clickToCopy: 'Kliknutím zkopírovat',
                    columnActions: 'Akce sloupce',
                    copiedToClipboard: 'Zkopírovat do schránky',
                    dropToGroupBy: 'Upustit pro seskupení podle sloupce {column}',
                    edit: 'Upraviť',
                    expand: 'Otvoriť',
                    expandAll: 'Otevřít vše',
                    filterArrIncludes: 'Obsahuje',
                    filterArrIncludesAll: 'Obsahuje všechno',
                    filterArrIncludesSome: 'Obsahuje některé',
                    filterBetween: 'Mezi',
                    filterBetweenInclusive: 'Mezi',
                    filterByColumn: 'Filtrovat podle sloupce {column}',
                    filterContains: 'Obsahuje',
                    filterEmpty: 'Prázdné',
                    filterEndsWith: 'Končí na',
                    filterEquals: 'Rovná se',
                    filterEqualsString: 'Rovná se',
                    filterFuzzy: 'Podobné',
                    filterGreaterThan: 'Větší než',
                    filterGreaterThanOrEqualTo: 'Větší nebo rovno',
                    filterInNumberRange: 'Mezi',
                    filterIncludesString: 'Obsahuje',
                    filterIncludesStringSensitive: 'Obsahuje',
                    filterLessThan: 'Menší než',
                    filterLessThanOrEqualTo: 'Menší nebo rovno',
                    filterMode: 'Režim filtru: {filterType}',
                    filterNotEmpty: 'Není prázdné',
                    filterNotEquals: 'Nerovná se',
                    filterStartsWith: 'Začíná na',
                    filterWeakEquals: 'Rovná se',
                    filteringByColumn: 'Filtrováno podle sloupce {column} - {filterType} {filterValue}',
                    goToFirstPage: 'První strana',
                    goToLastPage: 'Poslední strana',
                    goToNextPage: 'Další strana',
                    goToPreviousPage: 'Předchozí strana',
                    grab: 'Chytit',
                    groupByColumn: 'Seskupit podle sloupce {column}',
                    groupedBy: 'Seskupeno podle ',
                    hideAll: 'Skrýt vše',
                    hideColumn: 'Skrýt sloupec {column}',
                    max: 'Max.',
                    min: 'Min.',
                    move: 'Přesunout',
                    noRecordsToDisplay: 'Žádné záznamy k zobrazení',
                    noResultsFound: 'Nenalezeny žádné výsledky',
                    of: 'z',
                    or: 'nebo',
                    pinToLeft: 'Připnout doleva',
                    pinToRight: 'Připnout doprava',
                    resetColumnSize: 'Resetovat šířku sloupců',
                    resetOrder: 'Resetovat pořadí',
                    rowActions: 'Akce řádky',
                    rowNumber: '#',
                    rowNumbers: 'Čísla řádek',
                    rowsPerPage: 'Řádek na stránce',
                    save: 'Uložiť',
                    search: 'Hladať',
                    selectedCountOfRowCountRowsSelected: 'Vybráno {selectedCount} z {rowCount} řádek',
                    select: 'Vybrať',
                    showAll: 'Zobrazit vše',
                    showAllColumns: 'Zobrazit všechny sloupce',
                    showHideColumns: 'Zobrazit/Skrýt sloupce',
                    showHideFilters: 'Zobrazit/Skrýt filtry',
                    showHideSearch: 'Zobrazit/Skrýt hledání',
                    sortByColumnAsc: 'Seřadit podle sloupce {column} vzestupně',
                    sortByColumnDesc: 'Seřadit podle sloupce {column} sestupně',
                    sortedByColumnAsc: 'Seřazeno podle sloupce {column} vzestupně',
                    sortedByColumnDesc: 'Seřazeno podle sloupce {column} sestupně',
                    thenBy: ', pak podle ',
                    toggleDensity: 'Změnit hustotu řádek',
                    toggleFullScreen: 'Zobrazení na celou stránku',
                    toggleSelectAll: 'Vybrat vše',
                    toggleSelectRow: 'Vybrat řádku',
                    toggleVisibility: 'Zobrazení',
                    ungroupByColumn: 'Neseskupovat podle sloupce {column}',
                    unpin: 'Odepnout',
                    unpinAll: 'Odepnout vše',
                    unsorted: 'Neseřazeno'}}
                data={enumerationReducer.enumerationList}
                onEditingRowSave={handleSaveRowEdits}
                renderRowActions={({row, table}) => (
                    <Box sx={{display: 'flex', gap: '1rem'}}>
                        <Tooltip arrow placement="left" title="Upraviť">
                            <IconButton color="primary" onClick={() => table.setEditingRow(row)}>
                                <Edit/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip arrow placement="right" title="Vymazať">
                            <IconButton color="error" onClick={() => handleDeleteRow(row)}>
                                <Delete/>
                            </IconButton>
                        </Tooltip>
                    </Box>
                )}

                renderTopToolbarCustomActions={() => (
                    <Button
                        color="secondary"
                        onClick={() => setCreateModalOpen(true)}
                        variant="contained"
                        disabled={enumerationReducer.enumerationList.length === 9}
                    >
                        Vytvoriť novú kategóriu
                    </Button>
                )}
            />


            <CreateNewEnumerationModal
                columns={columns}
                open={createModalOpen}
                onClose={() => setCreateModalOpen(false)}
                onSubmit={handleCreateNewRow}
            />
        </Box>
    </Box>

}

export const CreateNewEnumerationModal = ({open, columns, onClose, onSubmit}) => {
    const [values, setValues] = useState(() =>
        columns.reduce((acc, column) => {
            acc[column.accessorKey ?? ''] = '';
            return acc;
        }, {}),
    );

    const handleSubmit = () => {
        //put your validation logic here
        onSubmit(values);
        onClose();
    };

    return (
        <Dialog open={open}>
            <DialogTitle textAlign="center">Vytvoriť novú kategóriu</DialogTitle>
            <DialogContent>
                <form onSubmit={(e) => e.preventDefault()}>
                    <Stack
                        sx={{
                            width: '100%',
                            minWidth: { xs: '300px', sm: '360px', md: '400px' },
                            gap: '1.5rem',
                        }}
                    >

                        <Typography>Farba</Typography>
                        <CirclePicker onChange={(e)=> setValues({ ...values, ["enumColor"]: e.hex })}/>




                            <TextField
                                key="enumName"
                                label="Názov"
                                required={true}
                                name="enumName"
                                onChange={(e) =>
                                       setValues({ ...values, [e.target.name]: e.target.value })
                                }
                            />
                    </Stack>
                </form>
            </DialogContent>
            <DialogActions sx={{ p: '1.25rem' }}>
                <Button color="primary" onClick={onClose}>Cancel</Button>
                <Button color="secondary" onClick={handleSubmit} variant="contained">
                    Vytvoriť
                </Button>
            </DialogActions>
        </Dialog>
    );

}
export default EnumerationList;