import {
    CREATE_ENUMERATION_SUCCESS, DELETE_ENUMERATION_SUCCESS,

    FETCH_ENUMERATION_FAILURE,
    FETCH_ENUMERATION_REQUEST,
    FETCH_ENUMERATION_SUCCESS, UPDATE_ENUMERATION_SUCCESS
} from "../action/enumerationAction";

const initialState = {
    loading: false,
    enumerationList:[]
}

export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_ENUMERATION_REQUEST:

            return {
                ...state,
                loading: true
            };
        case FETCH_ENUMERATION_SUCCESS:

            return {
                loading: false,
                enumerationList: action.payload,
                error: ''
            };

        case FETCH_ENUMERATION_FAILURE:
            // setError(action.payload)
            return {
                loading: false,
                enumerationList: '',
                error: action.payload
            };




        case CREATE_ENUMERATION_SUCCESS: {

            return {
                loading: false,
                enumerationList: [...state.enumerationList, action.payload],
                error: ''
            }
        }


        case UPDATE_ENUMERATION_SUCCESS: {

            return {
                loading: false,
                enumerationList: state.enumerationList.map(record => record.id !== action.payload.id ? record : action.payload),
                error: ''
            }
        }

        case DELETE_ENUMERATION_SUCCESS: {
console.log(action.payload)
            return {
                loading: false,
                enumerationList: state.enumerationList.filter((item) => item.id !== action.payload),
                error: ''
            }
        }











        default:
            return state
    }
};

