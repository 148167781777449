import {Stomp} from "@stomp/stompjs"
import SockJS from "sockjs-client"
import {
    CREATE_JOURNAL_SUCCESS, DELETE_ALL_JOURNAL,
    FETCH_JOURNAL_SUCCESS,
    SET_JOURNAL_ABSENT,
    SET_JOURNAL_SERVED
} from "../action/journalAction";

const setupSocket = (state) => {
    const options = {};
    const client = Stomp.over(() => new SockJS("/socket", null, options));

    client.connect({}, frame => {
        console.log('Connected: ' + frame);

        client.subscribe(`/topic/${state.getState().doctorReducer.doctor.id}/journal`, item => {
            console.log("msg, ", JSON.parse(item.body))

            const parsed = JSON.parse(item.body);
            switch (parsed.type){
                case "CREATE":
                    state.dispatch({
                        type: CREATE_JOURNAL_SUCCESS, payload: JSON.parse(item.body)
                    });
                    break;

                case "ABSENT":
                    state.dispatch({
                        type: SET_JOURNAL_ABSENT, payload:parsed.journal
                    });
                    break;

                case "SERVED":
                    state.dispatch({
                        type: SET_JOURNAL_SERVED, payload:parsed.journal
                    });
                    break;

                case "DELETEALL":
                    state.dispatch({
                        type: DELETE_ALL_JOURNAL, payload:[]
                    });
                    break;

                default:
                    console.log(parsed)

            }


        })
    });
}
export default setupSocket;