import {Avatar, Box, Button, Container, Paper, TextField, Typography} from "@mui/material";
import {Controller, useForm} from "react-hook-form";
import {findDoctor} from "../action/doctorAction";
import {useDispatch} from "react-redux";
import {getEnumerations} from "../action/enumerationAction";
import setupSocket from "../socket/Socket";
import store from "../store/store";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import ErrorNotification from "../snackBar/ErrorNotification";
import InfoSnack from "../snackBar/InfoSnack";

const Doctor = () => {
    const dispatch = useDispatch();
    const onSubmit = data => {
        dispatch(findDoctor(data))
            .then(responseData => {
                if (responseData && responseData.payload && responseData.payload.id) {
                    dispatch(getEnumerations(responseData.payload.id))
                        .then(() => setupSocket(store));
                } else {
                    // Handle the error case here, for example by displaying an error message
                    console.error("Error finding doctor:", responseData);
                }
            })
            .catch(error => {
                // Handle the error case here, for example by displaying an error message
                console.error("Error finding doctor:", error);
            });
    };
    const {control, handleSubmit} = useForm();
    return <>

        <Container>
            <Paper>
                <Box p={10}
                     sx={{
                         marginTop: 8,
                         display: 'flex',
                         flexDirection: 'column',
                         alignItems: 'center',
                     }}
                >


                    <Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
                        <LockOutlinedIcon/>
                    </Avatar>
                    <Typography component="h1" variant="h5">Login Page</Typography>

                    <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{mt: 1}}>

                        <Controller
                            name="user"
                            control={control}
                            render={({field}) => (<TextField
                                label="užívateľ"
                                fullWidth
                                required {...field} />)}
                        />

                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{mt: 3, mb: 2}}
                        >Prihlásenie</Button>

                    </Box>
                </Box>

            </Paper>
        </Container>
        <ErrorNotification/>
        <InfoSnack/>

    </>

}

export default Doctor;