import axios from "axios";
import {setError} from "./errorActions";
import {setInfo} from "./infoActions";

export const FETCH_DOCTOR_REQUEST = 'FETCH_DOCTOR_REQUEST';
export const FETCH_DOCTOR_SUCCESS = 'FETCH_DOCTOR_SUCCESS';
export const FETCH_DOCTOR_FAILURE = 'FETCH_DOCTOR_FAILURE';

export const RESET_DOCTOR = 'RESET_DOCTOR';
export const DOCTOR_FAILURE = 'DOCTOR_FAILURE';

export const CREATE_DOCTOR_SUCCESS = 'CREATE_DOCTOR_SUCCESS';
export const CREATE_DOCTOR_FAILURE = 'CREATE_DOCTOR_FAILURE';


export const FIND_DOCTOR_SUCCESS = 'FIND_DOCTOR_SUCCESS'


export const fetchDoctorRequest = () => {
    return {
        type: FETCH_DOCTOR_REQUEST
    }
};

export const fetchDoctorSuccess = Doctor => {
    return {
        type: FETCH_DOCTOR_SUCCESS, payload: Doctor
    }
};
export const fetchDoctorFailure = error => {
    return {
        type: FETCH_DOCTOR_FAILURE, payload: error
    }
};

export const DoctorFailure = error => {
    return {
        type: DOCTOR_FAILURE, payload: error
    }
};

export const resetDoctorToDefault = () => {
    return {
        type: RESET_DOCTOR
    }
};

export const resetDoctor = () => {
    return dispatch => {
        dispatch(resetDoctorToDefault())
    }
}



export const findDoctor = (loginId) => {
    return async dispatch => {
    console.log(loginId.user)
        return await axios.get(`api/doctor/login/${loginId.user}`, )
            .then(response => {
                console.log(response)
                return dispatch({
                    type: FETCH_DOCTOR_SUCCESS, payload: response.data
                })
            }).catch(error => {

                        dispatch(setError(error))

                }
                // dispatch({ type: CREATE_Doctor_RECORD_FAILURE,
                //     payload: err.data})
            );


    }
};


export const updateDoctorProfile = (doctor) => {
    return async dispatch => {
        // dispatch(fetchDoctorRequest());
        return await axios.put(`/api/doctor/update`, doctor)
            .then(response => {
              dispatch({
                    type: FETCH_DOCTOR_SUCCESS, payload: response.data
                })
                return dispatch(setInfo(response.status))

            }).catch(error => {

                        return dispatch(setError(error))

                }
                // dispatch({ type: CREATE_Doctor_RECORD_FAILURE,
                //     payload: err.data})
            );
    }


};


export const createDoctor = (Doctor) => {
    return (dispatch) => {
        return axios
            .post('/Doctor/'+ Doctor)
            .then(res => dispatch({
                type: CREATE_DOCTOR_SUCCESS, payload: res.data
            }))
            .catch(error => {
                    dispatch(setError(error))
                return dispatch({
                    type: CREATE_DOCTOR_FAILURE, payload: error.response.data
                })
            });
    };
}





