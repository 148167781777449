import {
    CREATE_JOURNAL_SUCCESS, DELETE_ALL_JOURNAL,
    FETCH_JOURNAL_FAILURE,
    FETCH_JOURNAL_REQUEST,
    FETCH_JOURNAL_SUCCESS, SET_JOURNAL_ABSENT, SET_JOURNAL_SERVED,
    SET_JOURNAL_SERVED_FAILD
} from "../action/journalAction";
import journalList from "../pages/journalList";
import {getTableSortLabelUtilityClass} from "@mui/material";


const initialState = {
    loading: false,
    journalList:{}
}

export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_JOURNAL_REQUEST:

            return {
                ...state,
                loading: true
            };
        case FETCH_JOURNAL_SUCCESS:

            return {
                loading: false,
                journalList: action.payload,
                error: ''
            };

        case DELETE_ALL_JOURNAL:

            return {
                loading: false,
                journalList: [],
                error: ''
            };

        case FETCH_JOURNAL_FAILURE:
            // setError(action.payload)
            return {
                loading: false,
                journalList: '',
                error: action.payload
            };



        case SET_JOURNAL_SERVED:



            let byd = state.journalList[action.payload.enumID].filter(j => j.id !== action.payload.id)
   state.journalList[action.payload.enumID]= byd

            return {
                loading: false,
                journalList: state.journalList,
                error: ''
            };


        case SET_JOURNAL_ABSENT:
            console.log("toto tu ", action.payload);
            const { enumID, id } = action.payload;
          //  state.journalList[action.payload.enumID] = state.journalList[action.payload.enumID].filter(j => j.id !== action.payload.id)
            const updatedJournalList = {
                ...state.journalList,
                [enumID]: state.journalList[enumID].filter(j => j.id !== id)
            };

            return {
                loading: false,
                journalList: updatedJournalList,
                error:''
            };


        case CREATE_JOURNAL_SUCCESS:



            if(Object.hasOwn(state.journalList,action.payload.journal.enumID)){

                state.journalList[action.payload.journal.enumID] = [...state.journalList[action.payload.journal.enumID], action.payload.journal]

            }else{


                state.journalList[action.payload.journal.enumID] = [action.payload.journal]

            }




            return {
                loading: false,
                journalList: state.journalList,
                error:''
            };














        default:
            return state
    }
};

