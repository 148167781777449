
import LoginPage from "./pages/loginPage";
import {useSelector} from "react-redux";
import DrawerNav from "./scenes/global/DrawerNav";


const App = () =>  {


    const doctorReducer = useSelector(state => state.doctorReducer);

  return (
    <div className="App">

        {doctorReducer.doctor?.id !== undefined ? <>




   <DrawerNav></DrawerNav>

        </>: <LoginPage></LoginPage>  }



    </div>
  );
}

export default App;
