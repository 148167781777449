import React, {useCallback, useEffect, useMemo} from 'react';
import OpeningHours from "./openingHours";
import DoctorDetail from "./doctorDetail";
import {getOpeningHours} from "../action/openingHoursAction";
import {useDispatch} from "react-redux";

const DoctorView =()=> {
    const memoizedDoctorDetail = useMemo(() => <DoctorDetail/>, []);

    return (
        <>
            {memoizedDoctorDetail}

        </>
    )
}
export default DoctorView;