export const SET_INFO = "SET_INFO";
export const HIDE_INFO = "HIDE_INFO";

// errorActions.js
export function setInfo(info){
    console.log("info" , info)
    return {
        type: SET_INFO,
        info: info
    }
}

export function hideInfo(){
    return {
        type: HIDE_INFO
    }
}

// errorReducer.js

const initState = {
    info: null,
    isOpen: false
};

export function infoReducer(state = initState, action){
    const { info } = action;

    if(info){
        return {
            info: info,
            isOpen: true
        }
    }else if(action.type === HIDE_INFO){
        return {
            info: null,
            isOpen: false
        }
    }

    return state;
}