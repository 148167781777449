export const SET_WARNING = "SET_WARNING";
export const HIDE_WARNING = "HIDE_WARNING";

// errorActions.js
export function setWarning(warning){

    return {
        type: SET_WARNING,
        warning: warning
    }
}

export function hideWarning(){
    return {
        type: HIDE_WARNING
    }
}

// errorReducer.js

const initState = {
    warning: null,
    isOpen: false
};

export function warningReducer(state = initState, action){
    const { warning } = action;

    if(warning){
        return {
            warning: warning,
            isOpen: true
        }
    }else if(action.type === HIDE_WARNING){
        return {
            warning: null,
            isOpen: false
        }
    }

    return state;
}