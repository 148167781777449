import React, {useMemo} from 'react';
import {Box, Button, Paper, Stack, Tooltip, Typography} from "@mui/material";
import {setAbsent, setServed} from "../action/journalAction";
import MaterialReactTable from "material-react-table";
import IconButton from "@mui/material/IconButton";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import AirlineSeatReclineExtraIcon from "@mui/icons-material/AirlineSeatReclineExtra";
import {useDispatch} from "react-redux";

import moment from "moment";
import 'moment/locale/sk';
const JournalItem =({journal, enumeration}) =>  {
    moment.locale('sk');

    const dispatch = useDispatch();
    const columns = useMemo(() => [{
        accessorKey: 'displayID',


        minSize: 5, //min size enforced during resizing
        maxSize: 5, //max size enforced during resizing
        size: 5, //medium column

        header: '#',
    }, {
        // accessorKey: 'dateCreated', //access nested data with dot notation
        header: 'cas',
        minSize: 5, //min size enforced during resizing
        maxSize: 5, //max size enforced during resizing
        size: 5, //medium column
        accessorFn: (row) => moment(row.dateCreated).fromNow()
    },], [],);

        return (
            <React.Fragment>
                <Paper elevation={3}>
                    <Box p={2} sx={{border: 8, borderColor:enumeration?.enumColor}}>

                        <Stack spacing={2}>
                            <Typography mx={{ wordBreak: "break-word" }}
                                        variant="h5"><b>{enumeration?.enumName}</b></Typography>
                            <Button color="secondary"  variant="contained"
                                    onClick={() => dispatch(setServed(journal[0]))}
                                    disabled={journal.length === 0}><b>Zavolať
                                > {journal[0]?.displayID}</b> </Button>

                            <MaterialReactTable data={journal}
                                                columns={columns}
                                                enableEditing
                                                enableDensityToggle={false}
                                                muiTableProps={{
                                                    sx: {
                                                        tableLayout: 'auto',
                                                    },
                                                }}

                                                displayColumnDefOptions={{
                                                    'mrt-row-actions': {
                                                        header: '', //change header text
                                                        size: 20, //make actions column wider
                                                    },
                                                }}


                                                initialState={{ pagination: { pageSize: 5} }}
                                                enableTopToolbar={false} //hide top toolbar
                                                enableColumnActions={false}
                                                enableBottomToolbar={true} //hide bottom toolbar
                                                renderRowActions={({row, table}) => (
                                                    <Box sx={{display: 'flex'}}>

                                                        <Tooltip arrow placement="right" title="Delete">
                                                            <IconButton color="error" size="large"
                                                                        onClick={() => dispatch(setAbsent(row.original))}>
                                                                <RemoveCircleIcon/>
                                                            </IconButton>
                                                        </Tooltip>

                                                        <Tooltip arrow placement="right" title="Delete">
                                                            <IconButton color="info" size="small"
                                                                        onClick={() => dispatch(setServed(row.original))}>
                                                                <AirlineSeatReclineExtraIcon/>
                                                            </IconButton>
                                                        </Tooltip>

                                                    </Box>)}
                            />
                        </Stack>


                    </Box>
                </Paper>
            </React.Fragment>
        );
}

export default JournalItem;