import {
    CREATE_OPENINGHOURS_SUCCESS, DELETE_OPENINGHOURS_SUCCESS,

    FETCH_OPENINGHOURS_FAILURE,
    FETCH_OPENINGHOURS_REQUEST,
    FETCH_OPENINGHOURS_SUCCESS, UPDATE_OPENINGHOURS_SUCCESS
} from "../action/openingHoursAction";

const initialState = {
    loading: false,
    openingHoursList:[]
}

export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_OPENINGHOURS_REQUEST:

            return {
                ...state,
                loading: true
            };
        case FETCH_OPENINGHOURS_SUCCESS:

            return {
                loading: false,
                openingHoursList: action.payload,
                error: ''
            };

        case FETCH_OPENINGHOURS_FAILURE:
            // setError(action.payload)
            return {
                loading: false,
                openingHoursList: '',
                error: action.payload
            };




        case CREATE_OPENINGHOURS_SUCCESS: {

            return {
                loading: false,
                openingHoursList: [...state.openingHoursList, action.payload],
                error: ''
            }
        }


        case UPDATE_OPENINGHOURS_SUCCESS: {

            return {
                loading: false,
                openingHoursList: state.openingHoursList.map(record => record.id !== action.payload.id ? record : action.payload),
                error: ''
            }
        }

        case DELETE_OPENINGHOURS_SUCCESS: {
console.log(action.payload)
            return {
                loading: false,
                openingHoursList: state.openingHoursList.filter((item) => item.id !== action.payload),
                error: ''
            }
        }











        default:
            return state
    }
};

