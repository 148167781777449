import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {HIDE_ERROR} from "../action/errorActions";
import {Alert, AlertTitle, Snackbar} from "@mui/material";


const ErrorNotification = (props) => {
    const isOpen = useSelector(state => state.errorReducer.isOpen);
    const error = useSelector(state => state.errorReducer.error);

    const dispatch = useDispatch();

    function handleClose(){
        dispatch({ type: HIDE_ERROR });
    }

    return (
        <>
            {isOpen && error && (
                <Snackbar open={isOpen} autoHideDuration={6000} onClose={handleClose}
                 anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                >
                <Alert onClose={handleClose} severity="error">
                    <AlertTitle> <br/>{error.response.status} {error.response.data.error}</AlertTitle>
                    <strong>INCIDENT : {error.response.data.message}{error.response.body}{error?.response?.data?.errorMessage} <br/> {error?.response?.data?.incidentId}</strong>
                </Alert>
                </Snackbar>
            )}
        </>
    )
}
export default ErrorNotification


