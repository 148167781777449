import {
    CREATE_DOCTOR_SUCCESS,
    FETCH_DOCTOR_FAILURE,
    FETCH_DOCTOR_REQUEST,
    FETCH_DOCTOR_SUCCESS
} from "../action/doctorAction";

const initialState = {
    loading: false,
    id: '', fullName: '', Comments: '', loginName: ''
}

export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_DOCTOR_REQUEST:

            return {
                ...state,
                loading: true
            };
        case FETCH_DOCTOR_SUCCESS:

            return {
                loading: false,
                doctor: action.payload,
                error: ''
            };

        case FETCH_DOCTOR_FAILURE:
            // setError(action.payload)
            return {
                loading: false,
                doctor: '',
                error: action.payload
            };




        case CREATE_DOCTOR_SUCCESS: {
            return {
                loading: false,
                doctor: action.payload,
                error: ''
            }
        }











        default:
            return state
    }
};

