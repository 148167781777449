import axios from "axios";
import {setError} from "./errorActions";
import store from "../store/store";


export const FETCH_OPENINGHOURS_REQUEST = 'FETCH_OPENINGHOURS_REQUEST';
export const FETCH_OPENINGHOURS_SUCCESS = 'FETCH_OPENINGHOURS_SUCCESS';
export const FETCH_OPENINGHOURS_FAILURE = 'FETCH_OPENINGHOURS_FAILURE';

export const CREATE_OPENINGHOURS_SUCCESS = 'CREATE_OPENINGHOURS_SUCCESS';
export const CREATE_OPENINGHOURS_FAILURE = 'CREATE_OPENINGHOURS_FAILURE';

export const UPDATE_OPENINGHOURS_SUCCESS = 'UPDATE_OPENINGHOURS_SUCCESS';
export const UPDATE_OPENINGHOURS_FAILURE = 'UPDATE_OPENINGHOURS_FAILURE';


export const DELETE_OPENINGHOURS_SUCCESS = 'DELETE_OPENINGHOURS_SUCCESS';
export const DELETE_OPENINGHOURS_FAILURE = 'DELETE_OPENINGHOURS_FAILURE';


export const fetchOpeningHoursRequest = () => {
    return {
        type: FETCH_OPENINGHOURS_REQUEST
    }
};

export const fetchOpeningHoursSuccess = OpeningHours => {
    return {
        type: FETCH_OPENINGHOURS_SUCCESS, payload: OpeningHours
    }
};
export const fetchOpeningHoursFailure = error => {
    return {
        type: FETCH_OPENINGHOURS_FAILURE, payload: error
    }
};


export const getOpeningHours = () => {
    return async dispatch => {

        return await axios.get(`api/opening-hours/${store.getState().doctorReducer.doctor.id}`,)
            .then(response => {
                return dispatch({
                    type: FETCH_OPENINGHOURS_SUCCESS, payload: response.data
                })
            }).catch(error => {
                    dispatch(setError(error))
            });


    }
};


export const createOpeningHours = (enumeration) => {
    return (dispatch) => {
        return axios
            .post(`/api/opening-hours`, enumeration)
            .then(res => dispatch({
                type: CREATE_OPENINGHOURS_SUCCESS, payload: res.data
            }))
            .catch(error => {
                if (error.response) {
                    dispatch(setError(error))
                }
                return dispatch({
                    type: CREATE_OPENINGHOURS_FAILURE, payload: error.response.data
                })
            });
    };
}


export const updateOpeningHours = (enumeration) => {
    return (dispatch) => {
        return axios
            .put(`/api/opening-hours`, enumeration)
            .then(res => dispatch({
                type: UPDATE_OPENINGHOURS_SUCCESS, payload: enumeration
            }))
            .catch(error => {
                if (error.response) {
                    dispatch(setError(error))
                }
                return dispatch({
                    type: UPDATE_OPENINGHOURS_FAILURE, payload: error.response.data
                })
            });
    };
}


export const deleteOpeningHours = (enumeration) => {
    return (dispatch) => {
        return axios
            .delete(`/api/enumeration/delete/${enumeration}`)
            .then(res => dispatch({
                type: DELETE_OPENINGHOURS_SUCCESS, payload: enumeration
            }))
            .catch(error => {
                if (error.response) {

                    dispatch(setError(error))
                }
                return dispatch({
                    type: DELETE_OPENINGHOURS_FAILURE, payload: error.response.data
                })
            });
    };
}




