import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Box, Button, Grid, Stack, Typography} from "@mui/material";
import {deleteAllJournals, getJournals} from "../action/journalAction";
import JournalItem from "./journalItem";
import FolderOffIcon from '@mui/icons-material/FolderOff';

const JournalList = () => {
    const dispatch = useDispatch();
    const journalReducer = useSelector(state => state.journalReducer);
    const enumerationReducer = useSelector(state => state.enumerationReducer);


    useEffect(() => {

        dispatch(getJournals())
    }, [])

    return <Grid container spacing={1} direction="column">
        <Grid item> <Button color="primary" variant="contained"
                            onClick={() => dispatch(deleteAllJournals())}>Zmazať všetky záznami</Button></Grid>
        <Grid item> <Grid container spacing={2}>

            {Object.keys(journalReducer.journalList).length > 0 ? Object.keys(journalReducer.journalList).sort().map((key, index) => (
                <Grid key={index} xs={6} sm={6} md={2} item>
                    <JournalItem key={index} journal={journalReducer.journalList[key]}
                                 enumeration={enumerationReducer.enumerationList.find(s => s.id === key)}/>
                </Grid>)) : <Grid item><Box sx={{
                marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center',
            }}><Stack><FolderOffIcon/><Typography>Ziaden zaznam</Typography></Stack></Box></Grid>}
        </Grid></Grid>
    </Grid>

}

export default JournalList;