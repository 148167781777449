import React from 'react';
import ReactDOM from 'react-dom/client';
import store from "./store/store";
import App from './App';
import {HashRouter} from "react-router-dom";
import {Provider} from 'react-redux';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const root = ReactDOM.createRoot(document.getElementById('root'));

const theme = createTheme(
    {
        palette:{
            primary:{main:"#253F3F"},
            secondary:{main:"#E91E63"}
        },
        typography: {
            fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
        }
    }
);
root.render(
    <Provider store={store}>

        <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <HashRouter>

                    <App/>

                </HashRouter>
            </LocalizationProvider>
        </ThemeProvider>

    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

