export const SET_ERROR = "SET_ERROR";
export const HIDE_ERROR = "HIDE_ERROR";

// errorActions.js
export function setError(error){
    console.log("error" , error)
    return {
        type: SET_ERROR,
        error: error
    }
}

export function hideError(){
    return {
        type: HIDE_ERROR
    }
}

// errorReducer.js

const initState = {
    error: null,
    isOpen: false
};

export function errorReducer(state = initState, action){
    const { error } = action;

    if(error){
        return {
            error: error,
            isOpen: true
        }
    }else if(action.type === HIDE_ERROR){
        return {
            error: null,
            isOpen: false
        }
    }

    return state;
}